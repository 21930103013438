import { render, staticRenderFns } from "./SplitPane.vue?vue&type=template&id=b9a5cd42&scoped=true&"
import script from "./SplitPane.vue?vue&type=script&lang=js&"
export * from "./SplitPane.vue?vue&type=script&lang=js&"
import style0 from "./SplitPane.vue?vue&type=style&index=0&id=b9a5cd42&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9a5cd42",
  null
  
)

export default component.exports